import axios from 'axios';
import authHeader from './auth-header';


const API_URL = process.env.VUE_APP_API_URL
const headers = { headers: authHeader() }

class ContentService {
  getContentsData() {
    return axios.get(`${API_URL}/content`, headers).then(resp => Array.isArray(resp.data) ? resp.data : [resp.data]);
  }

  getContents() {
    return axios.get(`${API_URL}/content`, headers);
  }

  async getDataContentById(courseId) {
    return await axios.get(`${API_URL}/content/${courseId}`, headers).then(resp => resp.data)
  }

  getContentById(courseId) {
    return axios.get(`${API_URL}/content/${courseId}`, headers).then(resp => resp.data);
  }

  createContent(payload) {
    console.log(payload)
    return axios.post(`${API_URL}/content`, payload, headers)
  }

  setContentModule(contentId, payload) {
    return axios.post(`${API_URL}/content/${contentId}/module`, payload, headers)
  }

  removeCourseModule(courseId, moduleId) {
    return axios.delete(`${API_URL}/course/${courseId}/module/${moduleId}`, headers)
  }

  getAllCourseModule(contentId) {
    return axios.get(`${API_URL}/content/${contentId}/module`, headers).then(resp => Array.isArray(resp.data) ? resp.data : [resp.data])
  }

  updateContent(contentId, payload) {
    return axios.put(`${API_URL}/course/${contentId}`, payload, headers)
  }

  updateCourseProp(courseId, payload) {
    return axios.patch(`${API_URL}/course/${courseId}`, payload, headers)
  }

  deleteContent(contentId) {
    return axios.delete(`${API_URL}/content/${contentId}`, headers)
  }

}

export default new ContentService();
