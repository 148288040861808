import axios from 'axios';
import authHeader from './auth-header';
import fileHeader from './file-header';

const API_URL = process.env.VUE_APP_API_URL
const headers = { headers: authHeader() }
const fileHeaders = { headers: fileHeader() }

class AgendaService {
  getAgendaData() {
    return axios.get(API_URL + '/agenda').then(resp => Array.isArray(resp.data) ? resp.data : [resp.data])
  }

  getAgenda() {
    return axios.get(API_URL + '/agenda')
  }

  getAgendaById(agendaId) {
    return axios.get(API_URL + '/agenda/' + agendaId, headers);
  }

  saveEventImage(formData) {
    return axios.post(API_URL + '/event/image', formData, fileHeaders);
  }

  createAgenda(payload) {
    return axios.post(API_URL + '/agenda', payload, headers).then(resp => Array.isArray(resp.data) ? resp.data : [resp.data]);
  }

  updateAgenda(agendaId, payload) {
    return axios.put(API_URL + '/agenda/' + agendaId, payload, headers);
  }

  deleteAgenda(agendaId) {
    return axios.delete(API_URL + '/agenda/' + agendaId, headers);
  }

}

export default new AgendaService();
