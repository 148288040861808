export const user = {
  namespaced: true,
  state: {
    user: null,
    userImage: ''
  },
  actions: {},
  mutations: {
    setUser(state, payload) {
      state.user = payload
    },
    setUserImage(state, payload) {
      state.userImage = payload
    }
  },

  getters: {
    getUser: state => state.user,
    getUserImage: state => state.user.userImage
  }
};
