import ModuleService from '@/services/module.service'

export default { 
  hadleBuildMap({ commit }, content) {
    console.log('oi');
    try {
      content.forEach(async item => {
        if (Array.isArray(item.modules)) {
          item.modules.forEach(async mdl => {
            const [ data ] = await ModuleService.getLessonsModule(mdl.id)
            mdl.lessons = Array.isArray(data.lessons) ? data.lessons : [data.lessons] 
          })
        } else {
          const [ data ] = await ModuleService.getLessonsModule(item.modules.id)
          item.modules.lessons = Array.isArray(data.lessons) ? data.lessons : [data.lessons] 
        }
      });

      commit('setCourseMap', content)
    } catch (err) {
      console.log(err)
    } 
  }
}
