import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL
const headers = { headers: authHeader() }

class AuthService {
  login(user) {
    return axios.post(API_URL + '/login', user).then(resp => resp.data);
  }

  checkEmail(email) {
    return axios.post(API_URL + `/check-password?email=${email}`, headers).then(resp => resp)
  }

  changeUser(user) {
    return axios
    .get(API_URL + '/active-subscriptions?email=' + user.email, headers)
    .then(response => {
      localStorage.setItem('user', JSON.stringify({username: user.email}))
      return response.data;
    })
  }

  register(payload) {
    return axios.post(API_URL + '/signup', payload).then(resp => resp.data);
  }
}

export default new AuthService();
