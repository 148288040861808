<template>
  <div class="col-md-12">
    <div class="card card-container">
      <img
        id="profile-img"
        src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
        class="profile-img-card"
      />
      <form name="form" @submit.prevent="handleRegister">
        <!-- Alterar pagina para cadastrar apenas senha já recebendo e-mail no query string -->
        <div>
          <!-- PEGAR VALOR DO E-MAIL E BLOQUEAR EDIÇÃO DO CAMPO -->
          <div class="form-group">
            <label for="email">E-mail</label>
            <input
              v-model="$route.query.email"
              :disabled="true"
              type="email"
              class="form-control"
              name="email"
            />
          </div>
          <!-- DUPLICAR CAMPO DE PASSWORD PARA CONFERIR SE A SENHA ESTÁ CORRETA -->
          <div class="form-group">
            <label for="password">Senha</label>
            <input
              v-model="user.password"
              type="password"
              class="form-control"
              name="password"
            />
          </div>

          <div class="form-group">
            <label for="password">Confirmar Senha</label>
            <input
              v-model="user.checkPassword"
              type="password"
              class="form-control"
              name="checkPassword"
            />
          </div>

          <div class="form-group">
            <button class="btn btn-primary btn-block" :disabled="disableButton">
              Registrar
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AuthService from "../services/auth.service.js";
import User from "../models/user";

export default {
  name: "ResetPass",
  data() {
    return {
      user: new User("", ""),
    };
  },
  computed: {
    loggedIn() {
      return JSON.parse(localStorage.getItem("user"));
    },

    disableButton() {
      return (
        this.user.password !== this.user.checkPassword ||
        this.user.password === ""
      );
    },
  },

  mounted() {
    if (this.loggedIn) {
      this.$router.push("/product");
    }
  },

  methods: {
    async handleRegister() {
      this.user.email = this.$route.query.email;
      this.user.username = this.$route.query.email;
      delete this.user.checkPassword;

      try {
        await AuthService.register(this.user);
        this.$toastSuccess("Redefinir senha", "Senha alterada com sucesso!");
        this.$router.push("/login");
      } catch (err) {
        console.log(err);
        this.$toastError(
          "Redefinir senha",
          "Algo deu errado, tente novamente!"
        );
      }
    },
  },
};
</script>
  
<style scoped>
  label {
    display: block;
    margin-top: 10px;
  }

  .card-container.card {
    max-width: 350px !important;
    padding: 40px 40px;
  }

  .card {
    background-color: #fff;
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  }

  .profile-img-card {
    width: 96px;
    height: 96px;
    margin: 0 auto 10px;
    display: block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }

  button[disabled]:hover {
    /* background: red; */
    opacity: 0.65;
  }
</style>