<template>
	<div class="col-md-12">
		<div class="card card-container">
			<b-icon 
				@click="$router.push('/login')"
				icon="arrow-left" 
				class="back-login" 
			/>
			<h3>Resetar Senha</h3>
			<b-form>
				<b-form-group label="E-mail">
					<b-form-input v-model="$v.form.email.$model" :state="formErrors.email"/>
					<b-form-invalid-feedback v-if="!$v.form.email.required" :state="formErrors.email">Campo email é obrigatório.</b-form-invalid-feedback>
          <b-form-invalid-feedback v-if="!$v.form.email.email" :state="formErrors.email">Campo email incorreto.</b-form-invalid-feedback>
				</b-form-group>

				<b-form-group>
					<b-button 
						@click="resetPassword"
						variant="primary" 
					>
						Resetar Senha
					</b-button>
				</b-form-group>
			</b-form>
		</div>
	</div>
</template>
  
<script>
import userService from '../services/user.service';
import { required, email } from 'vuelidate/lib/validators'

export default {
	data() {
		return {
			form: {
				email: ''
			},
			message: "",
			messageClass: ""
		};
	},

	validations: {
		form: {
			email: {
				required,
				email
			}
		}
	},

	computed: {
		formErrors() {
      return {
        email: this.$v.form.email.$error ? false : null
      }
    }
	},

	methods: {
		async resetPassword() {
			try {
				const resp = await userService.resetPassword(this.form.email)
				this.$toastSuccess('Recuperar senha', `E-mail de recuperação enviado para ${resp}`)
				this.$router.push({ path: '/login' })
			} catch (err) {
				console.log(err);
				const { status } = err?.response
				if ([404].includes(status)) {
					this.$toastError(`Erro ${status}`, 'E-mail não cadastrado em nossa base!')
				} else {
					this.$toastError(`Erro ${status}`, 'Por favor, entre em contato com o suporte!')
				}
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.card-container.card {
	max-width: 350px !important;
	padding: 40px 40px;
	background-color: #f7f7f7;
	margin: 50px auto;
	border-radius: 8px;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
	position: relative;
	h3 {
		text-align: center;
		color: var(--dark);
		margin-bottom: 24px;
	}

	::v-deep legend {
		color: var(--dark);
		margin: 0;
		padding: 0;
		font-weight: normal;
	}

	button {
		width: 100%;
	}
}

.back-login {
	font-size: 16px;
	position: absolute;
	top: 15px;
	left: 15px;
	color: var(--dark);
	cursor: pointer;
}
</style>