<template>
  <div class="header-default">
    <div class="titles text-white">
      <h1>Bem-Vindo ao Portal Licitante Extremo</h1>
      <h3>Aqui você acessa todas as aulas para se tornar o melhor licitante!</h3>
    </div>

    <b-button v-if="allAgendas.length" variant="warning" class="btn-lg mt-5" :to="{ hash: '#next-events' }">Próximos eventos</b-button>
    <ArrowAnimation v-if="allAgendas.length" class="arrow" />
    <div class="bottom-gradient"></div>
  </div>
</template>

<script>
import moment from "moment";
import AgendaService from "../../services/agenda.service";
import handleDataFormat from "../../services/handleDataFormat";
import ArrowAnimation from '@/components/home/ArrowAnimation'

export default {
  name: 'headerHomeDefault',
  components: {
    ArrowAnimation
  },
  data() {
    return {
      env: process.env.BASE_URL,
      eventBackgroundImageDefault: 'Users/sergio-ramos/Documents/licitante-extremo-frontend/dist/img',
      allAgendas: []
    }
  },

  async mounted() {
    try {
      const response = await AgendaService.getAgenda();
      this.allAgendas = handleDataFormat(response.data).filter(item => {
        const dateIqual      = moment() <= moment(item.eventDate)
        const liveInProgress = moment() <= moment(item.eventDate).add(2, 'hour')

        if (dateIqual && liveInProgress) {
          return item
        } else {
          return
        }
      })
    } catch (error) {
      console.error("Erro ao obter as agendas:", error);
    }
  }
}
</script>

<style lang="scss" scoped>
.header-default {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 700px;
  background: url('../../assets/images/bg-default.jpg') no-repeat;
  background-size: cover;
  position: relative;
  .titles {
    padding-top: 250px;
    text-align: center;
  }
}

.bottom-gradient {
  width: 100%;
  height: 200px;
  position: absolute;
  bottom: 0;
  background-image: linear-gradient(to bottom,rgba(0,0,0,0) 0,#131722 100%) !important;
  z-index: 9;
}

.arrow {
  margin-top: 130px;
}
</style>

