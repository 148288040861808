<template>
  <div>
    <b-nav v-if="userVuex" class="d-none d-md-flex" pills>
      <div v-for="(item, index) in menu" :key="index">
        <b-nav-item v-if="item.type === menuEnum.ROUTE" :to="item.url" v-show="item.show">
          <b-icon :icon="item.icon" class="item-nav-icon mr-2" />
          <span class="item-nav">{{ item.label }}</span>
        </b-nav-item>

        <b-nav-item
          v-if="item.type === menuEnum.LINK" 
          :href="item.url"
          target="_blank"
        >
          <i :class="item.icon" class="item-nav-icon mr-2"></i>
          <span class="item-nav">{{ item.label }}</span>
        </b-nav-item>

        <b-nav-item-dropdown
          v-if="item.type === menuEnum.DROP"
          right
        >
          <template #button-content>
            <img 
              v-if="userImage" 
              :src="`${env}/images?path=${userImage}`" 
              class="avatar" 
              @click="showAdm"
            />

            <b-icon v-else icon="person-circle" font-scale="2.5" @click="showAdm"></b-icon>
          </template>

          <b-dropdown-item v-for="(option, index) in item.options" :key="index" @click="option.click" v-show="option.show">
            <span>{{ option.label }}</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </div>
    </b-nav>

    <div class="nav-mobile d-md-none">
      <b-icon v-b-toggle.sidebar-right class="h2 m-4" icon="list"></b-icon>
      <b-sidebar id="sidebar-right" size="lg" right shadow>
        <div class="px-3 py-2">
          <div class="header">
            <div>
              <img 
                v-if="userImage" 
                :src="`${env}/images?path=${userImage}`" 
                class="avatar" 
              />
            </div>
            
            <div @click="$router.push('/profile')">
              <b-icon class="h2" icon="person-circle"></b-icon>
            </div>
            
            <div @click="this.handleLogout()">
              <b-icon class="h2" icon="box-arrow-right"></b-icon>
            </div>
         </div>
          
          <b-nav vertical>
            <b-nav-item v-for="(item, index) in menu" :key="index" :to="item.url" v-show="item.show">
              <b-icon :icon="item.icon" class="item-nav-icon mr-2" />
              {{ item.label }}
            </b-nav-item>
          </b-nav>
        </div>
      </b-sidebar>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import menuTypeEnum from '../enums/menuTypeEnum'
import UserTypeEnum from '../enums/userTypeEnum'
import MenuEnum from '../enums/menuTypeEnum'

export default {
  name: "UserMenu",
  
  data() {
    return {
      env: process.env.VUE_APP_API_URL,
      showMenu: false,
      menu: [
        {
          label: 'Início',
          icon: 'house-door',
          url: { hash: null },
          type: menuTypeEnum.ROUTE,
          show: this.$route.name === 'home'
        },
        {
          label: 'Home',
          icon: 'house-door',
          url: { path: '/' },
          type: menuTypeEnum.ROUTE,
          show: this.$route.name !== 'home'
        },
        {
          label: 'Cursos',
          icon: 'display',
          url: { hash: '#courses' },
          type: menuTypeEnum.ROUTE,
          show: this.$route.name === 'home'
        },
        {
          label: 'Aulas recentes',
          icon: 'collection-play',
          url: { hash: '#lessons' },
          type: menuTypeEnum.ROUTE,
          show: this.$route.name === 'home'
        },
        {
          label: 'Suporte',
          icon: 'fab fa-whatsapp mr-1 suporte',
          url: 'http://wa.me/5561999743536',
          type: menuTypeEnum.LINK
        },
        {
          image: true,
          label: '',
          icon: 'person-circle',
          url: '',
          type: menuTypeEnum.DROP,
          options: [
            {
              label: 'Administração',
              click: () => this.$router.push('/backoffice'),
              show: ''
            },
            {
              label: 'Perfil',
              click: () => this.$router.push('/profile'),
              show: true
            },
            {
              label: 'Logout',
              click: () => this.handleLogout(),
              show: true
            }
          ],
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      userVuex: 'user/getUser',
      userImage: 'user/getUserImage',
    }),

    menuEnum() {
      return MenuEnum
    },

    currentUser() {
      return JSON.parse(localStorage.getItem('currentUser'))
    }
  },

  watch: {
    '$route.name': function(val) {
      const arr1 = ['Início', 'Cursos', 'Aulas recentes']
      this.menu.forEach(item => {
        item.show = val === 'home'
        if (arr1.includes(item.label)) {
          item.show = val === 'home'
        } else {
          item.show = val !== 'home'
        }
      })
    }
  },

  methods: {
    showAdm() {
      const { roles } = this.currentUser
      const { options } = this.menu[5]
      options[0].show = roles.id === UserTypeEnum.ADMIN
    },
    
    handleLogout() {
      this.$store.commit('user/setUser', null)
      localStorage.removeItem('user');
      localStorage.removeItem('currentUser');
    }
  }

}
</script>

<style lang="scss" scoped>
nav {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .nav-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    color: var(--white);
    opacity: .9;
    transition: ease-in-out .3s;
    i, svg {
      font-size: 15px!important;
    }
    &:hover {
      opacity: 1;
      i, svg {
        color: var(--yellow)!important;
      }
    }
  }
}

::v-deep #sidebar-right {
  background: rgba(12, 16, 27, 0.95)!important;
  .b-icon {
    outline: none;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0px 30px 0;
    border-bottom: 1px dotted #eee;
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 26px;
      span {
        color: #fff
      }
    }
  }
  .close {
    color: var(--white)!important
  }
}

.avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%
}

::v-deep .b-nav-dropdown {
  .nav-link {
    background-color: transparent!important;
    &.active {
      background-color: transparent!important;
    }
  }

  ul {
    background-color: var(--dark);
    color: var(--ligth);
    li a:hover {
      background-color: transparent!important;
    }
  }
}

::v-deep .dropdown-toggle::after {
  display: none;
}
.icon-menu-mobile {
  cursor: pointer;
  &:focus {
    outline: none
  }
}
</style>