<template>
  <b-container fluid id="lessons" class="video-list">
    <h3 class="text-white">
      Aulas recentes
      <b-icon icon="dot" variant="warning" class="d-none d-sm-block"></b-icon>
    </h3>
    <b-row>
      <b-col
        cols="12"
        class="container-video-list mb-4"
      >
        <div
          v-for="(item, index) in sortedItems"
          :key="index"
          @click="redirectLesson(item)"
          class="item-group"
        >
          <div class="play-icon" @click="redirectLesson(item)">
            <b-icon icon="play"></b-icon>
          </div>
          <div
            :style="{ 'background-image': !item.lessonImage ? 'url('+`${localLessonImage}`+')' : 'url(' + `${env}/images?path=${item.lessonImage}` + ')' }"
            class="thumb"></div>

          <span class="title-video mt-2 px-3">{{ item.name }}</span>
          <small class="mb-2 px-3">{{ formatMomentDate(item.dateLesson) }}</small>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import moment from 'moment';

export default {
  name: 'LessonSwiper',
  props: {
    items: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      env: process.env.VUE_APP_API_URL,
      localLessonImage: 'https://back.licitanteextremo.com.br/images?path=/var/www/images/userImage/thumb.webp'
    }
  },
  computed: {
    sortedItems() {
      // Sort the items by dateLesson in descending order (most recent to oldest)
      const sortedItems = this.items.slice().sort((a, b) => {
        if (a.dateLesson === null || a.dateLesson === undefined) return 1;
        if (b.dateLesson === null || b.dateLesson === undefined) return -1;
        return b.dateLesson - a.dateLesson;
      });

      // Take the first 10 items from the sorted list
      return sortedItems.slice(0, 10);
    },
  },

  methods: {
    redirectLesson(lesson) {
      this.$emit('watchVideo', lesson)
    },

    formatMomentDate(date) {
      return moment(date).format('DD/MM/YYYY')
    }
  }
}
</script>

<style scoped lang="scss">
.video-list {
  h3 {
    margin: 50px 0 24px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    &::after {
      content: 'Confira as atualizações mais recentes';
      margin-top: 3px;
      font-size: .9rem;
      opacity: .8;
      @media (max-width: 580px) {
        display: none;
      }
    }
  }

  .container-video-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
    .title-video {
      font-size: 15px;
      letter-spacing: 1px;
      color: #fff
    }
    @media (max-width: 768px) {
      justify-content: center!important
    }
  }

}

.item-group {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  border-radius: 7px;
  background-size: cover;
  background: var(--zinc);
  .play-icon {
    width: 50px;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba($color: #000000, $alpha: .5);
    border-radius: 50%;
    transition: ease-in-out .5;
    cursor: pointer;
    svg {
      font-size: 24px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: var(--yellow)
    }
    &:hover {
      border: 1px solid var(--yellow)
    }
  }

  .thumb {
    width: 310px;
    height: 135px;
    border-radius: 7px 7px 0 0 ;
    overflow: hidden;
    cursor: pointer;
    background-position: top left;
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 580px) {
      width: 330px;
    }
  }
}
</style>
