<template>
  <div>
    <EventLive />
    <AgendaHome />
    <CourseList v-if="courses.length" :items="courses" :user-data="userData" />
    <LessonSwiper v-if="lessons.length" :items="lessons" @watchVideo="handleWatchVideo"/>
    <ViewVideoModal ref="viewVideo" :video="dataWatchVideo" />
    <Footer />
  </div>
</template>

<script>
// Services
import HomeService from "../services/home.service";
import UserService from "../services/user.service";
import LessonService from "../services/lesson.service";
import contentService from "../services/content.service";

// Components
import CourseList from '../components/CourseList'
import LessonSwiper from "../components/LessonSwiper"
import ViewVideoModal from '../components/ViewVideoModal'
import EventLive from '../components/home/EventLive'
import AgendaHome from '../components/AgendaHome'
import Footer from '../components/Footer'

export default {
  name: "Home",
  components: {
    CourseList,
    LessonSwiper,
    ViewVideoModal,
    EventLive,
    AgendaHome,
    Footer
  },
  data() {
    return {
      swiper: null,
      home: {},
      userProducts: [],
      displayDays: null,
      displayHours: null,
      displayMinutes: null,
      displaySeconds: null,
      content: "",
      agenda: [],
      courses: [],
      userData: {},
      userCourses: null,
      userLessons: null,
      lessons: [],
      dataLocalStorage: [],
      
      flagNoEvents: false,
      dataWatchVideo: {}
    }
  },

  filters: {
    addZero: function (num) {
      return num <= 0 ? 0 : num.toString().padStart(2, '0')
    }
  },

  computed: {
    user() {
      return JSON.parse(localStorage.getItem('user'))
    },

    selectedAgenda() {
      return this.agenda[0] || this.agenda
    }
  },

  async mounted() {
    const { id } = this.user

    this.home = await HomeService.getHomeData()
    this.userData = await UserService.getUserData(id)
    this.lessons = await LessonService.getLessonsData()
    this.courses = await contentService.getContentsData()

    // this.timer = setInterval(() => { this.showRemaining() }, 1000)
    // new Date(ano, mês, dia, hora, minuto, segundo, milissegundo);
    // Set localStoarge
    if (!localStorage.content) {
      this.courses.forEach((item) => this.dataLocalStorage.push([item.id, {}]))
      localStorage.setItem('content', JSON.stringify(Object.fromEntries(this.courses)))
    }
  },

  methods: {
    showRemaining() {
      const { eventDate } = this.selectedAgenda

      const now = new Date();
      const utcDateEnd = new Date(eventDate)
      utcDateEnd.setDate(utcDateEnd.getDate() + 1);

      const end = utcDateEnd;
      const distance = end.getTime() - now.getTime();
      this.setTimer(distance)

      distance < 0 && clearInterval(this.timer)
    },

    setTimer(distance) {
      const seconds = 1000
      const minutes = seconds * 60
      const hours = minutes * 60
      const days = hours * 24

      this.displayDays = Math.round(distance / days)
      this.displayHours = Math.floor((distance % days) / hours);
      this.displayMinutes = Math.floor((distance % hours) / minutes);
      this.displaySeconds = Math.floor((distance % minutes) / seconds);
    },

    handleWatchVideo(lesson) {
      this.$refs['viewVideo'].show()
      this.dataWatchVideo = lesson
    }
  }
};
</script>