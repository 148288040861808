<template>
  <div>
    <b-button 
      v-b-toggle.sidebar-backdrop
      class="float-button"
    >
      <b-icon icon="diagram3" variant="warning" />
      {{ title }}
    </b-button>

    <b-sidebar
      id="sidebar-backdrop"
      :title="title"
      :backdrop-variant="variant"
      :bg-variant="variant" 
      text-variant="light"
      backdrop
      shadow
    >
      <div class="px-3 py-2 content">
        <div 
          v-for="(item, index) in courseMap" 
          :key="index"
        >
          <b-card :img-src="`${env}/images?path=${item.portfolioImage}`" img-alt="Logo Módulo" img-left class="mb-3 p-2">
            <div v-if="Array.isArray(item.modules)" class="mdl-group">
              <div v-for="(mdl, index) in item.modules" :key="index" class="mdl">
                <div>
                  <span class="mdl__title">{{ mdl.name }}</span>
                  <b-button
                    :to="`/content/${item.id}/module/${mdl.id}`"
                    class="btn btn-outline-primary" size="sm">Acessar</b-button>
                </div>
              </div>
            </div>

            <div v-else class="mdl">
              <div>
                <span class="mdl__title">{{ item.modules.name }}</span>
                <b-button
                    :to="`/content/${item.id}/module/${item.modules.id}`"
                    class="btn btn-outline-primary" size="sm">Acessar</b-button>
              </div>
            </div>
          </b-card>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SiteMap',
  data() {
    return {
      env: process.env.VUE_APP_API_URL,
      variant: 'dark',
      title: 'Mapa de cursos'
    }
  },

  computed: {
    ...mapGetters({
      content: 'getContent',
      courseMap: 'getCourseMap'
    }),
  },

  mounted() {
    this.$store.dispatch('hadleBuildMap', this.content)
  }
}
</script>

<style lang="scss" scoped>
::v-deep .b-sidebar {
  width: 620px;
}
.content {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
  .card {
    border-radius: 7px;
    background: var(--zinc);
    .card-body {
      height: 210px;
      overflow-y: auto;
    }

    img {
      width: 150px;
      border-radius: 7px;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    .mdl-group {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
    .mdl {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding: 10px;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 7px;
      &__title {
        color: #9ca8b0
      }
      & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        .btn {
          border-radius: 7px;
        }
      }
    }
  }
}
  .float-button {
    position: fixed;
    bottom: 30px;
    right: -123px;
    z-index: 9999;
    border-radius: 20px 0 0 20px!important;
    transition: ease-in .3s;
    background: var(--zinc);
    &:hover {
      background: var(--yellow);
      right: 0px;
      .b-icon {
        color: var(--dark)!important
      }
    }
  }
</style>