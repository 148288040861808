<template>
  <div id="app">
    <div>
      <nav :class="{'dark': windowTop !== 0, 'relative': $route.name !== 'home'}">
        <div class="relative">
          <div class="d-flex align-items-center s-14 l-s-2">
            <router-link to="/" class="nav-link">
              <img src="./assets/img/licitante-extremo-logo-white.png" width="180px" />
            </router-link>
          </div>
        </div>

        <NavMenu v-if="userVuex" />
      </nav>
    </div>

    <div class="relative">
      <SiteMap />
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NavMenu from './components/Nav.vue'
import SiteMap from '@/components/SiteMap'

export default {
  components: {
    NavMenu,
    SiteMap
  },

  data() {
    return {
      isDesktop: true,
      toggleBackofficeMenu: false,
      env: process.env.VUE_APP_API_URL,
      windowTop: 0
    }
  },
  
  computed: {
    ...mapGetters({
      userVuex: 'user/getUser'
    }),

    isHome() {
      const { name } = this.$route
      return name === 'home'
    },

    showAdminBoard() {
      if (this.user) {
        return this.user.roles.includes("ROLE_ADMIN");
      }

      return false;
    }
  },

  watch: {
    userVuex(userVuex) {
      !userVuex && this.$router.push({ name: 'login' })
    }
  },

  created() {
    document.title = "Portal Licitante Extremo";
  },

  async mounted() {
    window.addEventListener("scroll", this.onScroll)
    this.isDesktop = window.innerWidth > 600;
    window.addEventListener('resize', this.handleResize);
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll)
  },


  methods: {
    onScroll() {
      this.windowTop = window.top.scrollY
    },
    toggleMenuBackofficeMobile() {
      this.toggleBackofficeMenu = !this.$store.state.auth.toggleMenu
      this.$store.dispatch('auth/toggleBackofficeMenu', this.toggleBackofficeMenu)
    },
    handleResize() {
      this.isDesktop = window.innerWidth > 600;
    },

    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize);
    }
  },
};
</script>

<style lang="scss" scoped>
@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css';

#app {
  background: #131722 !important;
  min-height: 100vh;
}

.suporte {
  font-size: medium;
  top: 5px;
  right: 0px
}

nav {
  width: 100%;
  position: fixed;
  z-index: 999;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: ease-in-out .3s;
  &.dark {
    background: rgba(12, 16, 27, 0.7);
  }
}
</style>
