<template>
  <b-container fluid id="courses" class="course-list">
    <h3 class="text-white">
      Cursos
      <b-icon icon="dot" variant="warning" class="d-none d-sm-block"></b-icon>
    </h3>

    <b-row>
      <b-col
        cols="12"
        class="container-list"
      >
          <span
            v-for="(item, index) in items"
            :key="index"
            @click="openLesson(item.id)"
            class="d-inline"
          >
            <figure>
              <img class="image-card" :src="`${env}/images?path=${item.portfolioImage}`" />
              <b-button variant="warning" size="md">Entrar</b-button>
            </figure>
          </span>
      </b-col>
    </b-row>
  </b-container>
</template>


<script>
import { userTypeEnum } from '../enums/userTypeEnum'

export default {
  props: {
    items: {
      type: Array,
      default: () => ([])
    },
    userData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      env: process.env.VUE_APP_API_URL,
      products: [
        {
          courses: []
        }
      ],
    }
  },

  methods: {
    openLesson(id) {
      this.$router.push(`/content/${id}`)
    },

    checkRules(item) {
      const { roles, products } = this.userData
      if (roles.id === userTypeEnum.ADMIN) {
        return false
      } else {
        if (Array.isArray(products)) {
          products.forEach(product => {
            const userHasCourse = product.courses.some(obj => obj.id === item.id)
            if (item.promo && userHasCourse) {
              item.visible = false
              return true
            } else if (item.promo && !userHasCourse) {
              return false
            } else {
              return !product.courses.some(obj => obj.id === item.id)
            }
          })
        } else {
          const userHasCourse = products.courses.some(obj => obj.id === item.id)
          if (item.promo && userHasCourse) {
            item.visible = false
            return true
          } else if (item.promo && !userHasCourse) {
            return false
          } else {
            return !products.courses.some(obj => obj.id === item.id)
          }
        }
      }
    },


  }
}
</script>

<style scoped lang="scss">
.course-list {
  h3 {
    margin: 50px 0 24px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    &::after {
      content: 'Conteúdos incríveis para você assistir quando quiser';
      margin-top: 3px;
      font-size: .9rem;
      opacity: .8;
      @media (max-width: 580px) {
        display: none;
      }
    }
  }

  .container-list {
    width: 100vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
    figure {
      width: 214px;
      margin: 0;
      overflow: hidden;
      position: relative;
      cursor: pointer;
      button {
        position: absolute;
        bottom: -15px;
        left: 50%;
        padding: 4px 32px;
        transform: translate(-50%, -50%);
        border-radius: 7px;
        opacity: 0;
        transition: .3s ease-in-out;
      }
      img {
        opacity: .8;
        -webkit-transition: .3s ease-in-out;
        transition: .3s ease-in-out;
      }
      &:hover {
        img {
          opacity: 1;
        }
        button {
          bottom: 0;
          opacity: 1;
        }
      }
      @media (max-width: 580px) { 
        width: 157px;
        justify-content: space-around!important
      }
    }
    @media (max-width: 768px) { 
      justify-content: center!important
    }
  }
}
.image-card {
  border-radius: 7px;
}
.img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  -webkit-transition: opacity .4s;
  transition: opacity .4s;
  cursor: pointer;

  &.blocked {
    background: rgba($color: #0c101b, $alpha: .8);
    cursor: default;
  }

  &.invisible {
    visibility: hidden;
  }

  .figcaption {
    margin-top: 40%;
  }
}
</style>
