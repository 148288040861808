<template>
  <b-container v-if="filteredAgendas.length" fluid id="next-events" class="schedule-home">
    <h3 class="text-white">
      Próximos eventos
      <b-icon icon="dot" variant="warning" class="d-none d-sm-block"></b-icon>
    </h3>
    <b-row>
      <b-col 
        v-for="item in filteredAgendas" 
        :key="item.id"
        cols="12"
        sm="12"
        md="6"
        lg="6"
        xl="4"
        class="mb-3"
      >
        <div class="item-calendar">
          <div class="badge text-lg-center bg-primary text-black mr-3">
            <div>
              <div>{{ item.eventDate | formatDateDay }}</div>
              <small  class="capitalize">{{ item.eventDate | formatDateMonthYear }}</small>
            </div>
          </div>

          <div class="info">
            <h5 class="text-primary my-3" v-b-tooltip.hover.top :title="item.eventName">
              {{ item.eventName }}
            </h5>
            <span class="d-block my-2">
              <i class="icon-placeholder-3"></i> 
              {{ item.eventPlace }}
            </span>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import AgendaService from "../services/agenda.service";
import moment from "moment";
import handleDataFormat from "../services/handleDataFormat";

export default {
  data() {
    return {
      agenda: [],
      filteredAgendas: [],
      productObject: {},
      userProducts: [],
      allAgendas: [],
      flagTodayEvent: false
    };
  },
  async mounted() {
    try {
      const response = await AgendaService.getAgenda();
      this.allAgendas = handleDataFormat(response.data);
      this.filteredAgendas = this.allAgendas.filter(item => {
        const dateIqual      = moment() <= moment(item.eventDate)
        const liveInProgress = moment() <= moment(item.eventDate).add(2, 'hour')

        if (dateIqual && liveInProgress) {
          return item
        } else {
          return
        }
      })
    } catch (error) {
      console.error("Erro ao obter as agendas:", error);
    }
  },

  filters: {
    formatDateDay(day) {
      const today = new Date(moment()).toDateString()
      const eventDate = new Date(day).toDateString()
      
      return today === eventDate ? 'Hoje' : moment(day).format("DD");
    },

    formatDateMonthYear(day) {
      const momentDate = moment(day);
      momentDate.locale("pt-br");
      return momentDate.format("MMMM, YYYY");
    },
  }
};
</script>
<style lang="scss" scoped>
.capitalize {
  text-transform: capitalize;
}

.schedule-home {
 h3 {
    margin: 50px 0 24px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    &::after {
      content: 'Fique por dentro dos eventos e encontros ao vivo';
      margin-top: 3px;
      font-size: .9rem;
      opacity: .8;
      @media (max-width: 580px) {
        display: none;
      }
    }
  }
}
.item-calendar {
  background: var(--zinc);
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 7px;
  .badge {
    padding: 20px;
    border-radius: 7px 0 0 7px;
    div { 
      font-size: 24px;
    }
    small {
      font-size: 14px;
    }
  }
  .info {
    padding-right: 15px;
    overflow: hidden;
    h5 {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
</style>
