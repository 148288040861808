export default function fileHeader() {
  let user = JSON.parse(localStorage.getItem('user'));
  const contentType = 'multipart/form-data';
  const acceptType = 'application/json'
  // console.log(localStorage)

  if (user && user.accessToken) {
    return {
      Authorization: 'Bearer ' + user.accessToken, 
      Accept: acceptType,
      'Content-Type': contentType
    }; // for Spring Boot back-end
    // return { 'x-access-token': user.accessToken };       // for Node.js Express back-end
  } else {
    return {};
  }
}
