import axios from 'axios';
import authHeader from './auth-header';
import userHeader from './user-header';
import fileHeader from './file-header';
import pandaHeader from './panda-header';
import handleDataFormat from '../services/handleDataFormat'

const API_URL = process.env.VUE_APP_API_URL
const headers = { headers: authHeader() }

const fileHeaders = { timeout: 8640000000000007, headers: fileHeader() }
const pdfHeaders = { timeout: 8640000000000007, responseType: 'blob', headers: fileHeader() }
const user = userHeader();
const pandaHeaders = { timeout: 8640000000000007, headers: pandaHeader() }
class LessonService {

  /*
  *   CRUD Lesson
  */ 
  getLessonsData() {
    return axios.get(`${API_URL}/lesson`, headers).then(resp => Array.isArray(resp.data) ? resp.data : [resp.data])
  }

  getLessons() {
    return axios.get(`${API_URL}/lesson`, headers)
  }

  getLessonById(lessonId) {
    return axios.get(`${API_URL}/lesson/${lessonId}`, headers);
  }

  getModulesFromLesson(lessonId) {
    return axios.get(`${API_URL}/lesson/module?lessonId=${lessonId}`, headers).then(resp => Array.isArray(resp.data) ? resp.data : [resp.data]);
  }

  createLesson(payload) {
    return axios.post(`${API_URL}/lesson`, payload, headers).then(resp => resp.data)
  }

  updateLesson(lessonId, payload) {
    return axios.put(`${API_URL}/lesson/${lessonId}`, payload, headers).then(resp => resp.data)
  }

  updateLessonProp(lessonId, payload) {
    return axios.patch(`${API_URL}/lesson/${lessonId}`, payload, headers)
  }

  deleteLesson(lessonId) {
    return axios.delete(`${API_URL}/lesson/${lessonId}`, headers).then(resp => resp.data)
  }


  /*
  *   Watch Lesson
  */ 
  markLessonAsWatched(lessonId) {
    return axios.post(`${API_URL}/lesson/${lessonId}/mark-as-watched?userId=${user.id}`, headers).then(resp => resp.data)
  }

  getMarkedAsWatched() {
    return axios.get(`${API_URL}/lesson/mark-as-watched?userId=${user.id}`, headers).then(resp => handleDataFormat(resp.data).map(item => item.lesson))
  }

  getMarkedAsWatchedByLessonIdAndUserId(lessonId) {
    return axios.get(`${API_URL}/lesson/${lessonId}/mark-as-watched?userId=${user.id}`, headers)
  }

  deleteMarkAsWatched(lessonId) {
    return axios.delete(`${API_URL}/lesson/${lessonId}/mark-as-watched?userId=${user.id}`, headers).then(resp => resp.data)
  }


  /*
  *   Images Lesson
  */ 
  saveLessonImage(formData) {
    return axios.post(API_URL + '/lesson/image', formData, fileHeaders).then(resp => resp.data);
  }


  /*
  *   Panda Vídeo
  */ 
  getAllVideos() {
    return axios.get(`https://api-v2.pandavideo.com.br/videos/`, pandaHeaders).then(resp => resp.data)
  }

  getVideoData(videoId) {
    return axios.get(`https://api-v2.pandavideo.com.br/videos/${videoId}`, pandaHeaders).then(resp => resp.data)
  }

  deleteVideoFromPanda(id) {
    const payload = { data: JSON.stringify({ video_id : id }) }
    console.log({ pandaHeaders })
    return axios.delete(`https://api-v2.pandavideo.com.br/videos/`, payload, pandaHeaders).then(resp => resp.data)
  }

  uploadVideo(formData) {
    return axios.post(`${API_URL}/video/upload`, formData, fileHeaders).then(resp => resp.data)
  }

  /*
  * Material
  */
  uploadMaterial(payload) {
    return axios.post(`${API_URL}/material`, payload, fileHeaders).then(resp => resp.data)
  }

  getMaterials() {
    return axios.get(`${API_URL}/material`, headers);
  }

  getMaterialByLessonId(lessonId) {
    return axios.get(`${API_URL}/material/lesson/${lessonId}`, headers).then(resp => Array.isArray(resp.data) ? resp.data : [resp.data]);
  }

  deleteMaterial({ id }) {
    return axios.delete(`${API_URL}/material/${id}`, headers);
  }

  getMaterialByPath(file) {
    const url = `${API_URL}/material/download?file=${encodeURIComponent(file)}`;
    return axios.get(url, pdfHeaders);
  }

  /*
  *   Comments Lesson
  */  
  getCommentsFromLesson(lessonId) {
    return axios.get(`${API_URL}/comment/lesson/${lessonId}`, headers).then(resp => resp.data)
  }

  addCommentToLesson(payload) {
    return axios.post(`${API_URL}/comment`, payload, headers)
  }
}

export default new LessonService();
