import ContentService from "../services/content.service";
import handleDataFormat from '../services/handleDataFormat'
import { router } from '@/router';

export const content = {
  namespaced: true,
  state: {
    content: null,
    modules: null
  },

  actions: {
    async requestContentById({ commit }) {
      const { contentId } = router.history.current.params
      const content = await ContentService.getDataContentById(contentId)
      const modules = handleDataFormat(content.modules)

      commit('content', content)
      commit('modules', modules.sort((a, b) => a.moduleIndex - b.moduleIndex))
    }
  },

  mutations: {
    content(state, payload) {
      state.content = payload
    },
    modules(state, payload) {
      state.modules = payload
    }
  },

  getters: {
    content: state => state.content,
    modules: state => state.modules
  }
};
