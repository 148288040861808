import ModuleService from '@/services/module.service'
import LessonService from '@/services/lesson.service'
import { router } from '@/router';

export default {

  async apiGetLessons({ commit }) {
    const { moduleId } = router.history.current.params
    const { lessons } = await ModuleService.getModuleById(moduleId)
    const arrLessons = Array.isArray(lessons) ? lessons : [lessons]
    const arrLessonsSortByLessonIndex = arrLessons.sort((a, b) =>  Number(a.lessonIndex) - Number(b.lessonIndex))
    
    commit('setLessons', arrLessonsSortByLessonIndex)
  },

  async apiGetLessonsWatched({ commit }) {
    const lessonsWatched = await LessonService.getMarkedAsWatched()
    commit('setLessonsWatched', lessonsWatched.map(item => item.id))
  },

  async apiGetLessonVideo({ commit }) {
    const { video } = router.history.current.query
    
    if (!video) return
    const videoProps = await LessonService.getVideoData(video)
    commit('setVideoLesson', videoProps)
  },

  async setCurrentLesson({ state, commit }) {
    const { lessonId } = router.history.current.query
    const currentLesson = state.lessons?.filter(item => item?.id == lessonId)[0]
    commit('setCurrentLesson', currentLesson)
  },

  async resetLesson({ commit }) {
    commit('setCurrentLesson', {})
  }
}