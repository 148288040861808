<template>
  <div class="col-md-12">
    <vue-topprogress ref="topProgress" color="#fec703" />
    <div class="card card-container">
      <h3 class="text-center">Login</h3>
      <form name="form" @submit.prevent="actionSubmit">
        <!-- Email -->
        <div class="form-group">
          <label for="username">
            E-mail
            <span v-b-tooltip.hover.right title="Preencha com seu e-mail do ato da compra!" class="email-tootip">
              <b-icon icon="question-circle-fill" ></b-icon>
            </span>
          </label>
          <input
            v-model="user.email"
            v-validate="'required'"
            type="text"
            class="form-control"
            name="username"
            required
            data-cy="username"
          />

        </div>

        <!-- Senha -->
        <div v-if="emailExist" class="form-group">
          <label for="password">Senha</label>
          <input
            v-model="user.password"
            v-validate="'required'"
            type="password"
            class="form-control"
            name="password"
            data-cy="password"
          />
        </div>

        <!-- Action -->
        <div class="form-group">
          <button class="btn btn-primary btn-block mb-3" :disabled="loading" data-cy="button-login">
            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
            <span>{{ emailExist ? 'Entrar' : 'Enviar' }}</span>
          </button>

          <b-alert :show="!!msgFeedBackError" variant="danger">
            {{ msgFeedBackError }}
            <b-icon icon="exclamation-circle-fill" variant="danger"></b-icon>
          </b-alert>

          <b-alert :show="!!msgFeedBackSuccess" variant="success">
            {{ msgFeedBackSuccess }}
            <b-icon icon="exclamation-circle-fill" variant="danger"></b-icon>
          </b-alert>
        </div>

        <!-- Esqueci minha senha -->
        <div v-if="emailExist" class="form-group d-flex justify-content-end">
          <a href="/forgot-password">Esqueci a senha</a>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AuthService from '../services/auth.service'
import UserService from '@/services/user.service'

export default {
  name: 'Login',
  data() {
    return {
      emailExist: false,
      user: {},
      loading: false,
      message: '',
      createPasswordMessage: '',
      createPasswordMessageClass: '',
      hasPasswordFlag: false,
      msgFeedBackError: '',
      msgFeedBackSuccess: ''
    };
  },
  computed: {
    loggedIn() {
      return !!this.$store.state.user.user
    },

    userStorage() {
      return JSON.parse(localStorage.getItem('user'))
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/product');
    }
  },
  methods: {
    actionSubmit() {
      this.emailExist ? this.handleLogin() : this.checkEmail()
    },

    async checkEmail() {
      try {
        this.$refs.topProgress.start()
        const { email } = this.user
        const resp = await AuthService.checkEmail(email)
        if ([200].includes(resp.status)) {
          this.emailExist = true;
          this.msgFeedBackError = ''
        }

        if([202].includes(resp.status)) {
          this.msgFeedBackSuccess = 'Um e-mail foi enviado com o link para página Cadastro de Senha! CONFIRA SUA CAIXA DE SPAM! Caso não tenha recebido em até 5 minutos, entre em contato com o suporte no whatsapp http://wa.me/556199743536'
        }
      } catch (err) {
        const { status } = err.response

        const messages = {
          404: 'E-mail não cadastrado na Hotmart!',
          503: 'Ocorreu um erro interno no servidor!',
          403: 'Este e-mail não possui produto ativo!'
        }

        this.msgFeedBackError = messages[status]
      } finally {
        this.$refs.topProgress.done()
      }
    },

    async handleLogin() {
      try {
        this.$refs.topProgress.start()
        
        const requests = await Promise.all([
          AuthService.login(this.user)
        ])

        localStorage.setItem('user', JSON.stringify(requests[0]))
        this.$store.commit('user/setUser', requests[0])
        
        const user = await UserService.getUserData(this.userStorage?.id)
        localStorage.setItem('currentUser', JSON.stringify(user))

        this.$store.commit('user/setUser', user)
        this.$store.commit('user/setUserImage', user.userImage)
        
        this.$router.push({ path: '/' })
      } catch (err) {
        this.toast('Login', 'Login ou senha incorretos!', 'danger')
      } finally {
        this.$refs.topProgress.done()
      }
    },

    toast(title, text, variant) {
      this.$bvToast.toast(text, {
        title: title,
        solid: true,
        autoHideDelay: 5000,
        variant
      })
    }
  }
};
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

::v-deep .alert {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.email-tootip {
  width: 20px;
  display: inline-block;
}
</style>
