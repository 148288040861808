import Vue from 'vue';
import Vuex from 'vuex';

import { auth } from './auth.module';
import { content } from './contentById.module';
import { user } from './user.module';
import vuexLesson from './lesson'
import vuexCertification from './certification'
import vuexGeneral from './general'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    content,
    user,
    vuexLesson,
    vuexCertification,
    vuexGeneral
  }
});
