import { BToast } from 'bootstrap-vue'

export default {
  name: 'toast',
  install: (app) => {
    app.prototype.$toastSuccess = (title, label) => {
      const BootstrepToaster = new BToast()

      return BootstrepToaster.$bvToast.toast(label, {
        title,
        solid: true,
        autoHideDelay: 5000,
        variant: 'success'
      })
    } 

    app.prototype.$toastError = (title, label) => {
      const BootstrepToaster = new BToast()

      return BootstrepToaster.$bvToast.toast(label, {
        title,
        solid: true,
        autoHideDelay: 5000,
        variant: 'danger'
      })
    } 
  }
}
