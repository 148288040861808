<template>
  <div>
    <b-modal
      ref="modal-view-video"
      hide-footer
      size="xl"
      :title="video.name"
      @hide="hide"
    >
      <iframe
        v-if="previewVideo"
        width="100%"
        height="500"
        :src="`${previewVideo}&color=FEC703`"
        frameborder="0"
      />
      <div>
        <h4>Descrição</h4>
        <p>{{ video.description }}</p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import LessonService from "../services/lesson.service"

export default {
  name: 'ViewVideoModal',
  props: {
    video: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      idVideo: '',
      previewVideo: ''
    }
  },

  watch: {
    async video(val) {
      if (!val) return
      const { video } = val
      this.idVideo = video

      const pandaVideo = await LessonService.getVideoData(video)
      this.previewVideo = pandaVideo.video_player
    }
  },

  methods: {
    show() {
      this.$refs['modal-view-video'].show()
    },

    hide() {
      this.previewVideo == ''
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .modal-dialog {
  margin-top: 120px;
}

::v-deep .modal-content {
  background: var(--dark)!important;
  h4, h5, .close {
    color: var(--yellow);
    opacity: 1;
  }
  .modal-header {
    border: none
  }
}

.feedback-msg {
  text-align: center;
}
</style>
