<template>
  <div>
    <div
      v-if="live"
      class="live-section"
      :style="{ 'background-image': 'url(' + `${env}/images?path=${live.eventBackgroundImage}` + ')'}"
    >
      <div class="call-action">
        <div class="relative text-white">
          <h1 class="mb-2">
            {{ live.eventName }}
          </h1>
          <h3 class="mb-2">
            {{ live.eventSubtitle }}
          </h3>
          <div class="d-flex align-items-center mt-4">
            <div>
              <i class="icon-placeholder-3 mr-1"></i>
              <span>{{ live.eventPlace }}</span>
            </div>
            <div class="ml-3">
              <i class="icon-clock mr-2"></i>
              <span>{{ liveDate }} às {{ liveHour }}</span>
            </div>
          </div>
        </div>
        
        <TimerEvents 
          v-if="timerEvent" 
          :time="timerEvent" 
          :title="live.eventName" 
          @finish="activeLive"
        />

        <div class="live-actions mt-1">
          <b-button 
            :href="`https://${live.eventUrl}`" 
            :disabled="disableLiveButton"
            target="_blank" 
            class="btn btn-lg btn-primary"
          >
            <span v-if="!disableLiveButton" class="live-icon"></span>
            Acessar 
          </b-button>
        </div>
      </div>
      <div class="bottom-gradient"></div>
    </div>

    <HeaderHomeDefault v-else />
  </div>
</template>

<script>
import moment from 'moment';
import AgendaService from "@/services/agenda.service";
import TimerEvents from '@/components/TimerEvents'
import HeaderHomeDefault from '@/components/home/HeaderHomeDefault'

export default {
  name: 'eventLive',
  components: {
    TimerEvents,
    HeaderHomeDefault
  },
  data() {
    return {
      env: process.env.VUE_APP_API_URL,
      liveDate: null,
      live: null,
      timerEvent: 0,
      liveHour: '',
      liveInProgress: null,
      disableLiveButton: true
    }
  },

  computed: {
    now() {
      return moment().valueOf()
    }
  },

  async created() {
    try {
      const allDates = await AgendaService.getAgendaData()
      const validDates = allDates.filter(item => moment(item.eventDate).add(2, 'hour').valueOf() >= this.now)
      // Has a live today
      validDates.forEach(item => {
        if (this.dateFomatter(item.eventDate) === this.dateFomatter(this.now)) {
          this.live = item
          this.timerEvent = this.live.eventDate
          this.liveDate = moment(this.timerEvent).format('D [de] MMMM, YYYY')
          this.liveHour = moment(this.timerEvent).format('HH:mm')
          this.$set(item, 'endLive', moment(item.eventDate).add(2, 'hour').valueOf())
          this.liveInProgress = setInterval(this.handleLiveInProgress, 1000);
        }
      })
    } catch(err) {
      console.log(err)
    }
  },

  methods: {
    dateFomatter(date) {
      return new Date(moment(date)).toDateString()
    },

    activeLive() {
      this.disableLiveButton = false
    },

    handleLiveInProgress() {
      const now = new Date().valueOf()
      if (now >=this.live.endLive) {
        this.live = null
        clearInterval(this.liveInProgress);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.live-section {
  position: relative;
  height: 700px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  z-index: 0;
  .call-action {
    position: absolute;
    top: 150px;
    padding: 0 50px;
    h1 {
      letter-spacing: 1px
    }
  }
  .bottom-gradient {
    width: 100%;
    height: 200px;
    position: absolute;
    bottom: 0;
    background-image: linear-gradient(to bottom,rgba(0,0,0,0) 0,#131722 100%) !important;
  }

  .live-actions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 16px;
    .btn {
      width: 300px;
    }
    i {
      color: var(--yellow)
    }
  }
}

.live-icon {
  position: relative;
  top: calc(50% - 5px);
  left: calc(-35% - 5px);
  display: inline-block;
  background-color: red;
  width: 10px;
  height: 10px;
  border: 1px solid rgba(black, 0.1);
  border-radius: 50%;
  z-index: 1;
  &:before {
    content: "";
    display: block;
    position: absolute;
    background-color: rgba(red, 0.6);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation: live 2s ease-in-out infinite;
    z-index: -1;
  }
}

@keyframes live {
  0% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(3.5, 3.5);
    background-color: rgba(red, 0);
  }
}

</style>