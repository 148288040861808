export default {
  setLessons(state, payload) {
    state.lessons = payload
  },

  setVideoLesson(state, payload) {
    state.videoLesson = payload
  },

  setCurrentLesson(state, payload) {
    state.currentLesson = payload
  },

  setLessonsWatched(state, payload) {
    state.lessonsWatched = payload
  }
}