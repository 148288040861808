import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

import Vuelidate from  'vuelidate'
import VueTheMask from 'vue-the-mask'

import App from './App.vue';
import { router } from './router';
import store from './store';

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import vueTopprogress from 'vue-top-progress'

import toast from './plugins/toast'

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt
} from '@fortawesome/free-solid-svg-icons';

import "@/assets/css/main.css"

library.add(faHome, faUser, faUserPlus, faSignInAlt, faSignOutAlt);

Vue.config.productionTip = false;

Vue.use(Vuex);
Vue.use(Vuelidate);
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(vueTopprogress)
Vue.use(VueTheMask)
Vue.use(toast)

// Configurar o vuex-persist
const vuexPersist = new VuexPersist({
  key: 'my-vuex', // chave para o armazenamento local
  storage: window.localStorage, // ou window.sessionStorage
});

// Adicionar o plugin vuex-persist ao store Vuex
vuexPersist.plugin(store);


Vue.component('font-awesome-icon', FontAwesomeIcon);

// Initialize dataLayer variable
/* eslint-disable no-undef */ // disable eslint for dataLayer variable
var dataLayer = window.dataLayer || [];
/* eslint-enable no-undef */ // enable eslint

window.dataLayer = window.dataLayer || [];

// Define function to load Google Analytics tracking code
function loadGoogleAnalytics() {
  // Load tracking code asynchronously
  const script = document.createElement('script');
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-JSR3YSZCQ9';
  script.async = true;
  document.head.appendChild(script);

  // Initialize tracking code
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'G-JSR3YSZCQ9');
}

// Call function to load tracking code
loadGoogleAnalytics();

// Defina o título da página aqui
document.title = 'Portal Licitante Extremo';

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
