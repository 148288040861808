import axios from 'axios';
import authHeader from './auth-header';
import fileHeader from './file-header';

// const API_URL = process.env.BASE_URL;
const API_URL = process.env.VUE_APP_API_URL
const headers = { headers: authHeader() }
const fileHeaders = { headers: fileHeader() }

class UserService {

  getPublicContent() {
    return axios.get(API_URL + '/all');
  }

  getModeratorBoard() {
    return axios.get(API_URL + '/mod', headers);
  }

  getAdminBoard() {
    return axios.get(API_URL + '/admin', headers);
  }

  // user endpoints

  getUserBoard() {
    return axios.get(API_URL + '/user', headers);
  }

  getUserData(id) {
    return axios.get(API_URL + `/user/${id}`, headers).then(resp => resp.data)
  }

  getUser(id) {
    return axios.get(API_URL + `/user/${id}`, headers)
  }

  createUser(payload) {
    return axios.post(API_URL + '/user', payload, headers);
  }

  updateUserData(payload, id) {
    return axios.patch(API_URL + '/user/' + id, payload, headers).then(resp => resp.data);
  }

  deleteUser(userId) {
    return axios.delete(API_URL + '/user/' + userId, headers);
  }

  getUserList() {
    return axios.get(API_URL + '/user', headers);
  }

  getActiveSubscriptionsList() {
    return axios.get(API_URL + '/active-subscriptions', headers);
  }

  getUserActiveSubscription(email) {
    return axios.get(`${API_URL}/active-subscriptions?email=${email}`, headers);
  }

  getAllUserSubscription(email) {
    return axios.get(`${API_URL}/active-subscriptions?email=${email}&request=all`, headers).then(resp => resp)
  }

  getUserProducts(userId) {
    return axios.get(API_URL + '/user/' + userId, headers).then(resp => resp.data);
  }

  // certification endpoints

  getCertificationBoard() {
    return axios.get(API_URL + '/certification', headers);
  }

  resetPassword(email) {
    return axios.post(`${API_URL}/reset-password?email=${email}`, headers).then(resp => resp.data)
  }


  saveUserImage(formData) {
    return axios.post(API_URL + '/user/image', formData, fileHeaders).then(resp => resp.data)
  }

}

export default new UserService();
