<template>
  <div>
    <div v-if="timeLeft > 0">
      <span class="timer">Iniciará em {{ timeLeftFormated }}</span>
    </div>
    <div v-else>
      <span class="timer text-white">Live acontecendo agora, clique no botão abaixo para acessar!</span>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'timerEvent',
  props: {
    time: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      timeLeft: 0,
      timeLeftFormated: '',
      intervalId: null
    };
  },
  
  mounted() {
    const now = moment()
    const limitTime = moment(this.time)
    this.timeLeft = limitTime.diff(now, 'seconds');
    this.intervalId = setInterval(this.updateTimer, 1000);
  },

  beforeDestroy() {
    clearInterval(this.intervalId);
  },

  methods: {
    updateTimer() {
      if (this.timeLeft === 0) {
        clearInterval(this.intervalId);
        this.$emit('finish')
      } else {
        this.timeLeft--
        const duration = moment.duration(this.timeLeft, 'seconds')
        this.timeLeftFormated = moment.utc(duration.asMilliseconds()).format('HH:mm:ss')
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.timer {
  display: block;
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px;
  color: var(--yellow)
}
</style>
