import axios from 'axios';
import authHeader from './auth-header';
import fileHeader from './file-header';

const API_URL = process.env.VUE_APP_API_URL
const headers = { headers: authHeader() }
const fileHeaders = { headers: fileHeader() }

class HomeService {

  getHomeData() {
    return axios.get(API_URL + '/home').then(resp => resp.data)
  }

  getHome() {
    return axios.get(API_URL + '/home')
  }

  createHome(payload) {
    return axios.post(API_URL + '/home', payload, headers);
  }

  saveHomeImage(formData) {
    return axios.post(API_URL + '/home/image', formData, fileHeaders);
  }

}

export default new HomeService();
