import Vue from 'vue';
import Router from 'vue-router';
import store from './store';
import Home from './views/Home.vue';
import Login from './views/Login.vue';
import Register from './views/Register.vue';
import ForgotPassword from './views/ForgotPassword.vue';
import ResetPassword from './views/ResetPassword.vue';
// import Agenda from './views/Agenda.vue';
import notFound from './views/NotFound.vue'

Vue.use(Router);

export const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/404',
      name: 'not-found',
      meta: { requiresAuth: false },
      component: notFound,
    },
    {
      path: '/',
      name: 'home',
      meta: { requiresAuth: true },
      component: Home,
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/register',
      component: () => import('./views/Register.vue')
    },
    {
      path: '/register/:email',
      component: Register,
      props: true
    },
    {
      path: '/profile',
      name: 'profile',
      meta: { requiresAuth: true },
      // lazy-loaded
      component: () => import('./views/Profile.vue')
    },
    {
      path: '/backoffice',
      name: 'backoffice',
      meta: { requiresAuth: true },
      // lazy-loaded
      component: () => import('./views/Backoffice.vue')
    },
    {
      path: '/mod',
      name: 'moderator',
      meta: { requiresAuth: true },
      // lazy-loaded
      component: () => import('./views/BoardModerator.vue')
    },
    {
      path: '/user',
      name: 'user',
      meta: { requiresAuth: true },
      // lazy-loaded
      component: () => import('./views/BoardUser.vue')
    },
    {
      path: '/product',
      name: 'product',
      meta: { requiresAuth: true },
      // lazy-loaded
      component: () => import('./views/Product.vue')
    },
    {
      path: '/content/:contentId',
      name: 'content',
      meta: { requiresAuth: true },
      // lazy-loaded
      component: () => import('./views/Content.vue')
    },
    {
      path: '/content/:contentId/module/:moduleId',
      name: 'module',
      meta: { requiresAuth: true },
      // lazy-loaded
      component: () => import('./views/Module.vue')
    },
    {
      path: '/module/:moduleId',
      name: 'moduleOnly',
      meta: { requiresAuth: true },
      // lazy-loaded
      component: () => import('./views/Module.vue')
    },
    {
      path: '/certification',
      name: 'certification',
      meta: { requiresAuth: true },
      // lazy-loaded
      component: () => import('./views/Certification.vue')
    },
    {
      path: '/certification/:id/',
      name: 'certification',
      meta: { requiresAuth: true },
      // lazy-loaded
      component: () => import('./views/Exam.vue')
    },
    {
      path: '/certification/:id/pre-exam',
      name: 'pre-exam',
      meta: { requiresAuth: true },
      // lazy-loaded
      component: () => import('./views/PreExam.vue')
    },
    {
      path: '/certification/:id/pos-exam',
      name: 'pos-exam',
      meta: { requiresAuth: true },
      // lazy-loaded
      component: () => import('./views/PosExam.vue')
    },
    // {
    //   path: '/event/:agendaId',
    //   name: 'event',
    //   meta: { requiresAuth: true },
    //   // lazy-loaded
    //   component: () => import('./views/Event.vue')
    // },
    {
      path: '/certificate-emission',
      name: 'certificate-emission',
      meta: { requiresAuth: true },
      // lazy-loaded
      component: () => import('./views/CertificateEmission.vue')
    },
    {
      path: '/certificate-revision',
      name: 'certificate-revision',
      meta: { requiresAuth: true },
      // lazy-loaded
      component: () => import('./views/CertificateRevision.vue')
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      meta: { requiresAuth: false },
      component: ForgotPassword,
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      meta: { requiresAuth: false },
      component: ResetPassword,
      props: true
    },
    // {
    //   path: '/agenda',
    //   name: 'agenda',
    //   meta: { requiresAuth: true },
    //   component: Agenda,
    // },
    {
      path: '/page',
      name: 'page',
      meta: { requiresAuth: true },
      // lazy-loaded
      component: () => import('./views/Page.vue')
    },
    {
      path: '*',
      redirect: '/404' // Redirecionar qualquer rota não correspondida para a página 404
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return window.scrollTo({ 
        top: document.querySelector(to.hash).offsetTop - 100, 
        behavior: 'smooth' 
      })
    } else {
      return window.scrollTo({ 
        top: 0, 
        behavior: 'smooth' 
      })
    }
  }
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = localStorage.getItem('user')

  if (requiresAuth && !isAuthenticated) {
    next('/login')
  } else {
    if (to.name === 'login' && isAuthenticated) {
      next('/')
    } else {
      store.commit('user/setUser', JSON.parse(localStorage.getItem('currentUser')))
      next()
    }
  } 
});

export default {
  props: ['email'],
  router
}
