import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL
const headers = { headers: authHeader() }

class ModuleService {

  /*
  *   CRUD Modules
  */ 
  createModule(payload) {
    return axios.post(`${API_URL}/module`, payload, headers).then(({ data }) => Array.isArray(data) ? data : [data])
  }

  editModule(payload) {
    const { id } = payload
    return axios.put(`${API_URL}/module/${id}`, payload, headers).then(({ data }) => Array.isArray(data) ? data : [data])
  }

  deleteModule(moduleId) {
    return axios.delete(`${API_URL}/module/${moduleId}`, headers).then(({ data }) => Array.isArray(data) ? data : [data])
  }

  // GETs
  getDataModules() {
    return axios.get(`${API_URL}/module/all`, headers).then(({ data }) => Array.isArray(data) ? data : [data])
  }

  getLessonsModule(moduleId) {
    return axios.get(`${API_URL}/module/${moduleId}/lesson`, headers).then(({ data }) => Array.isArray(data) ? data : [data])
  }

  getModules() {
    return axios.get(`${API_URL}/module`, headers);
  }

  getModuleById(moduleId) {
    return axios.get(`${API_URL}/module/${moduleId}/lesson`, headers).then(resp => resp.data)
  }

  getDataModuleById(moduleId) {
    return axios.get(`${API_URL}/module/${moduleId}`, headers).then(resp => resp.data)
  }

  getAllModuleLesson(moduleId) {
    return axios.get(`${API_URL}/module/${moduleId}/lesson`, headers)
  }

  // POSTs / SETs 
  associateLesson(moduleId, payload) {
    return axios.post(`${API_URL}/module/${moduleId}/lesson`, payload, headers)
  }

  removeAssociateLesson(moduleId, paylaod) {
    return axios.post(`${API_URL}/module/${moduleId}/lesson/delete`, paylaod, headers)
  }

  updateModuleProp(moduleId, payload) {
    return axios.patch(`${API_URL}/module/${moduleId}`, payload, headers)
  }

  reorderModuleLessonIndex(payload) {
    return axios.put(`${API_URL}/module/lesson/index`, payload, headers)
  }

  setIndexLesson({ idModule, idLesson, indexLesson }) {
    return axios.put(`${API_URL}/module/${idModule}/lesson/${idLesson}/index/${indexLesson}`, headers).then(resp => resp.data)
  }
}

export default new ModuleService();
